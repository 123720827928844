// import "lity";

export default {
	init() {
		// JavaScript to be fired on all pages

		// add the body class to fade in
		$('body').addClass('loaded');

		// activate the mega menu underlay background
		$('.ao-mega .has-sub-menu').hover(
			function() {
				$('.ao-mega--bg').addClass('active');
			}, function() {
				$('.ao-mega--bg').removeClass('active');
			}
		);

		// activate the search field in the header
		$('.ao-header--search--svg').on('click',function(){
			$(this).siblings('#search').toggleClass('active');
		});

		// toggle the mobile menu 
		$('.ao-header--nav--mobile--toggle').on('click',function(){
			$(this).toggleClass('active');
			$('.ao-mm').toggleClass('active');
		});		

		// deactivate mobile menu top level links if they have sub items
		// also show the sub menu
		$('.has-sub-menu > .ao-mm--mega--top-link').on('click',function(e){
			e.preventDefault();
			$(this).siblings('.ao-mm--mega--sub').addClass('active');
		});

		// clicking the back button hides the sub menu
		$('.ao-mm--mega--sub--back').on('click',function(e){
			$(this).parent('.ao-mm--mega--sub').removeClass('active');
		});		

		// clicking a sub link shows the reveal
		$('.ao-mm--mega--sub--link').on('click',function(e){
			e.preventDefault();
			$(this).toggleClass('active');
			$(this).siblings('.ao-mm--mega--sub--reveal').slideToggle();
		});

		
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};
